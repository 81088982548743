import { CircularProgress, Typography,Button} from '@mui/material'
import { Box } from '@mui/system'
import React,  {  useState } from 'react';
import { useNavigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy} from '@fortawesome/free-solid-svg-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard';
const FullscreenOverlaySubmitted = ({ content, sum, albumId}: { content?: string, sum: Number, albumId?: string}) => {
    const [textToCopy, setTextToCopy] = useState('');
    const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied

    const onCopyText = () => {
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
    };
    const navigate = useNavigate();


    return (
        <Box sx={{

            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 99999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: "rgba(0,0,0,0.5)"
        }}>

                <Box p={4} bgcolor="#FFF" borderRadius={4} maxWidth="280px">
                    <Typography my={2} textAlign="center" fontWeight="bold">As of 01.06.2024, there is a fee of 4.99€+VAT per song.</Typography>

                    <Typography my={2} textAlign="center" fontWeight="normal">
                        Please make a bank transfer to Fairmus using the information below and we will approve your release within 24h. Please make sure to use the payment description provided.

                    </Typography>

                    {copyStatus && <Typography textAlign="center" color={"green"}>Text copied to clipboard!</Typography>}
                    <Typography textAlign="center" fontWeight="normal">Total For this album:

                    </Typography>
                    <Typography textAlign="center" fontWeight="bold">{sum.toFixed(2)}€

                        <CopyToClipboard text={sum.toFixed(2)} onCopy={onCopyText}>
                            <FontAwesomeIcon style={{paddingLeft:'6px'}}  cursor="pointer" icon={faCopy} />
                        </CopyToClipboard>

                    </Typography>
                    <Typography textAlign="center" fontWeight="normal">Payment recipient:</Typography>
                    <Typography textAlign="center" fontWeight="bold">Fairmus Technologies OÜ

                        <CopyToClipboard text="Fairmus Technologies OÜ" onCopy={onCopyText}>
                            <FontAwesomeIcon style={{paddingLeft:'6px'}}   cursor="pointer"  icon={faCopy} />
                        </CopyToClipboard>
                    </Typography>
                    <Typography textAlign="center" fontWeight="normal">Recipient Account:</Typography>
                    <Typography textAlign="center" fontWeight="bold">EE027700771010415600
                        <CopyToClipboard text="EE027700771010415600" onCopy={onCopyText}>
                            <FontAwesomeIcon style={{paddingLeft:'6px'}}  cursor="pointer" icon={faCopy} />
                        </CopyToClipboard>
                    </Typography>
                    <Typography textAlign="center" fontWeight="normal">Payment Description:</Typography>
                    <Typography textAlign="center" fontWeight="bold">{albumId}
                        <CopyToClipboard  text={albumId} onCopy={onCopyText}>
                            <FontAwesomeIcon style={{paddingLeft:'6px'}} cursor="pointer"  icon={faCopy} />
                        </CopyToClipboard>
                    </Typography>
                    <Typography my={2}  textAlign="center" fontWeight="bold">
                    <Button  variant="outlined" fontWeight="bold" onClick={()=>{
                        navigate('/albums')
                    }
                    }>OK</Button>
                    </Typography>
                </Box>


        </Box>
    )
}

export default FullscreenOverlaySubmitted
