import { Timestamp } from '@firebase/firestore';
import { Alert, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router";
import ArtistAutocompleteField from '../../components/fields/ArtistAutocompleteField';
import GenreAutocompleteField from "../../components/fields/GenreAutocompleteField";
import ImageFileUpload from "../../components/fields/ImageFileUpload";
import SongAutocompleteField from '../../components/fields/SongAutocompleteField';
import YearAutocompleteField from "../../components/fields/YearAutocompleteField";
import FullscreenOverlayLoader from "../../components/FullscreenOverlayLoader";
import Navigation from '../../components/Navigation';
import useAlbums from '../../hooks/useAlbums';
import useFirestore from "../../hooks/useFirestore";
import useGenres from '../../hooks/useGenres';
import useRecordings from '../../hooks/useRecordings';
import { IArtist } from "../../models/IArtist";
import { IGenre } from "../../models/IGenre";
import { IRecording } from "../../models/IRecording";
import { useUser } from "../../store/context/UserContext";
import { getImageUrl, uploadImage } from "../../utils";
import {DatePicker} from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import etLocale from 'date-fns/locale/et';

export interface IAlbumFields {
    name?: string;
    recordingYear?: number | null;
    isSingle?: boolean;
    img?: string;
    genres?: IGenre[];
    songs?: IRecording[];
    artists?: IArtist[];
    createdAt?: Timestamp | null;
    imageFile?: File | null;
    approved?: boolean;
    availableFrom?: Date | null;
}

const AlbumsCreator = () => {
    const [fields, setFields] = useState<IAlbumFields>({});
    const [errors, setErrors] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isCreatorDisabled, setIsCreatorDisabled] = useState(false);
    const { state: { data: user } } = useUser();
    const { add, update } = useFirestore();
    const navigate = useNavigate();
    const { recordings, fetchRecordings, fetchRecordingById } = useRecordings();
    const { fetchAlbums, fetchAlbumById } = useAlbums();
    const { enqueueSnackbar } = useSnackbar();
    const { fetchGenreById } = useGenres();
    const { id } = useParams();
    const isEditing = !!id;

    const imageFile = fields?.imageFile ?? null;

    const getRecordings = async () => {
        const data = await fetchRecordings({
            force: recordings.length <= 0 ? true : false
        });
        if (data.length <= 0) setIsCreatorDisabled(true);
    }

    useEffect(() => {
        if (user) getRecordings();
    }, []);

    useEffect(() => {
        if (isEditing) {
            (async () => {
                const album = await fetchAlbumById(id);
                if (!album) {
                    enqueueSnackbar('Album not found', { variant: 'error' });
                    navigate('/albums');
                    return;
                }

                // Lets get all the recording songs
                const songs: IRecording[] = [];
                for (const songId of album.songs) {
                    const song = await fetchRecordingById(songId);
                    if (song) songs.push(song);
                }

                const genres: IGenre[] = [];
                for (const genreId of album.genres) {
                    const genre = await fetchGenreById(genreId);
                    if (genre) {
                        if (album?.source !== 'err' && genre.name === 'ERR') break;
                        genres.push(genre);
                    }
                }

                setFields((p) => ({
                    ...p,
                    name: album.name,
                    recordingYear: album.recordingYear ?? null,
                    isSingle: album.isSingle ?? false,
                    img: album.img ?? null,
                    genres: genres,
                    songs: songs,
                    createdAt: album.createdAt,
                    approved: album.approved ?? false,
                    availableFrom: album.availableFrom?.toDate() ?? null,
                }))
            })();
        }
    }, [isEditing]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let errors = [];
        if (!fields?.name) errors.push('name');
        if (!fields?.recordingYear) errors.push('recordingYear');
        if (!fields?.availableFrom) errors.push('availableFrom');
        if (!fields?.genres || fields?.genres?.length <= 0) errors.push('genres');
        if (!fields?.songs || fields?.songs?.length <= 0) errors.push('songs');
        if (!imageFile && !fields?.img) errors.push('imageFile');


        if(isEditing){
            // @ts-ignore
            const created = new Date(fields.createdAt)
            console.log(fields.createdAt);
            console.log(fields.availableFrom?.getTime());
            // @ts-ignore
            if(fields?.availableFrom?.getTime() < fields.createdAt?.seconds * 1000){

                errors.push('availableFrom')
                enqueueSnackbar('Available From should be after the creation date', { variant: 'error' });
                return;
            }
        } else {
            const now = new Date();
            now.setSeconds(0);
            now.setMinutes(0);
            now.setHours(0);
            // @ts-ignore
            if(fields?.availableFrom.getTime() < now.getTime()){
                errors.push('availableFrom')
                enqueueSnackbar('Available From should be either now or in the future', { variant: 'error' });
                return;
            }

        }

        if (errors.length > 0) {
            setErrors(errors);
            enqueueSnackbar('Please fill all the required fields', { variant: 'error' });
            return;
        } else setErrors([]);

        const genreIds = fields.genres?.map((genre) => genre.id) ?? [];
        const artistIds = new Set(fields.songs?.map((song) => song.artists).flat()); // create unique array of artist ids
        const songIds = fields.songs?.map((song) => song.id) ?? [];

        const albumData = {
            name: fields.name,
            genres: genreIds,
            artists: Array.from(artistIds), // have to transform array to pure JavaScript objects because firebase doesn't accept 'new Set()'
            songs: songIds,
            createdAt: fields?.createdAt ?? new Date(),
            img: fields?.img ?? null,
            isSingle: fields?.isSingle ?? false,
            recordingYear: fields?.recordingYear ?? null,
            ownerId: user?.id,
            approved: fields?.approved ?? false,
            availableFrom: fields?.availableFrom ?? null,
        };

        setIsSubmitting(true);
        if (isEditing) {
            try {
                // If we have changed the image file, update it
                let newUrl = fields?.img ?? null;
                if (imageFile) {
                    await uploadImage(id, 'albums', imageFile as File);
                    const mediaUrl = getImageUrl(id, 'albums', imageFile as File);
                    newUrl = mediaUrl

                    // Update all selected songs with album image
                    await Promise.all(songIds.map(async (songId) => {
                        await update('songs', songId, {
                            img: mediaUrl
                        });
                    }));
                }

                await update('albums', id, { ...albumData, img: newUrl });

                await Promise.all(songIds.map(async (songId) => {
                    await update('songs', songId, {
                        availableFrom: fields?.availableFrom ?? null
                    });
                }));

                // Fetch all albums and recordings again
                await fetchAlbums({ force: true });
                await fetchRecordings({ force: true });

                enqueueSnackbar('Album updated', { variant: 'success' });
                setIsSubmitting(false);
                navigate('/albums');
            } catch (err) {
                console.error('Updating album failed', err);
                enqueueSnackbar('Error updating album', { variant: 'error' });
                setIsSubmitting(false);
            }
            return;
        }

        try {
            const album = await add('albums', albumData);
            // Upload image
            await uploadImage(album.id, 'albums', imageFile as File);
            const imageUrl = getImageUrl(album.id, 'albums', imageFile as File);

            // Update album with uploaded image
            await update('albums', album.id, {
                img: imageUrl
            });

            // Update all selected songs with album image
            await Promise.all(songIds.map(async (songId) => {
                await update('songs', songId, {
                    img: imageUrl,
                    availableFrom: fields?.availableFrom ?? new Date(),
                });
            }));

            // Fetch all albums and recordings again
            await fetchAlbums({ force: true });
            await fetchRecordings({ force: true });
             /* Display payment information */
            //enqueueSnackbar('Album created', { variant: 'success' });
            //setIsSubmitting(false);
            //navigate('/albums');
        } catch (err) {
            console.error(`Adding album failed: `, err);
            enqueueSnackbar('Error creating an album, try again later', { variant: 'error' });
            setIsSubmitting(false);
            return;
        }
    };

    return (
        <Box display="flex" pb={12}>
            <Navigation />
            <Box p={{ xs: 2, md: 4 }} maxWidth="1200px" width="100%">
                <Typography variant="h3" fontWeight="bold">{isEditing ? 'Edit' : 'Add'} Album</Typography>
                {isCreatorDisabled && (
                    <Alert severity="warning" sx={{ mt: 4, alignItems: 'center' }}>
                        You haven't created any recordings yet. Add your first recording to create an album.
                    </Alert>
                )}
                <Box mt={8} />
                {recordings.length > 0 && (
                    <Box mt={8} maxWidth="500px" mx="auto">
                        <form onSubmit={handleSubmit}>
                            <Box>
                                <AlbumInfoForm
                                    id={id}
                                    fields={fields}
                                    errors={errors}
                                    setFields={(name, value) => setFields((p) => ({ ...p, [name]: value }))}
                                />
                            </Box>
                            <Box sx={{ my: 4 }} />
                            <Box width="100%" display="flex" justifyContent="center">
                                <Button
                                    variant="contained"
                                    type='submit'
                                >
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    </Box>
                )}
            </Box>
            {isSubmitting && <FullscreenOverlayLoader />}
            {isSubmitted && <FullscreenOverlayLoader />}
        </Box>
    )
}

interface IAlbumInfoFormProps {
    id?: string;
    fields: IAlbumFields;
    errors: string[];
    includeSongsAutocomplete?: boolean;
    includeArtistsAutocomplete?: boolean;
    artistsAutocompleteHelperText?: string;
    setFields: (name: string, value: any) => void;
}

export const AlbumInfoForm = ({
    id,
    fields,
    errors,
    includeSongsAutocomplete = true,
    includeArtistsAutocomplete = false,
    artistsAutocompleteHelperText,
    setFields
}: IAlbumInfoFormProps) => {
    const { recordings } = useRecordings();

    const isError = (name: string) => errors?.includes(name) ?? false;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Album name"
                    value={fields?.name ?? ''}
                    onChange={(e) => setFields('name', e.target.value)}
                    error={isError('name')}
                    sx={{ width: "100%" }}
                />
            </Grid>
            <Grid item xs={12}>
                <YearAutocompleteField
                    value={fields?.recordingYear ?? null}
                    label="Recording year"
                    error={isError('recordingYear')}
                    onChange={(event, value) => setFields('recordingYear', value)}
                />
            </Grid>
            <Grid item xs={12}>
                <GenreAutocompleteField
                    value={fields.genres?.map((genre) => {
                        return { ...genre, inputValue: genre.name ?? '' }
                    }) ?? []}
                    error={isError('genres')}
                    onValueChange={(genres) => setFields('genres', genres)}
                    label="Genres"
                />
            </Grid>
            {includeSongsAutocomplete && (
                <Grid item xs={12}>
                    <SongAutocompleteField
                        value={fields.songs?.map((song) => {
                            return { ...song, inputValue: song.name ?? '' }
                        }) ?? []}
                        label="Songs"
                        error={isError('songs')}
                        options={recordings.map((recording) => {
                            return { ...recording, inputValue: recording.name }
                        })}
                        onValueChange={(songs) => setFields('songs', songs)}
                    />
                </Grid>
            )}
            {includeArtistsAutocomplete && (
                <Grid item xs={12}>
                    <ArtistAutocompleteField
                        value={fields.artists?.map((artist) => {
                            return { ...artist, inputValue: artist.name ?? '' }
                        }) ?? []}
                        error={isError('artists')}
                        onValueChange={(artists) => setFields('artists', artists)}
                        label="Artists"
                        helperText={artistsAutocompleteHelperText}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={etLocale}>
                    <DatePicker
                        mask="__.__.____"
                        label="Available from"
                        value={fields?.availableFrom}
                        minDate={!!id && id.length > 0 ? fields.createdAt : new Date()}
                        onChange={(newValue) => {
                            if(newValue !== null) {
                                //@ts-ignore
                                const date = new Date(newValue);
                                date.setSeconds(0);
                                date.setMinutes(0);
                                date.setHours(0);
                                setFields('availableFrom', date);
                            } else {
                                setFields('availableFrom', null);
                            }
                        }}

                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <div className={"css-hlotfb-MuiFormHelperText-root"}>
                    {"The date this album becomes streamable on Fairmus"}
                </div>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="isSingle"
                            checked={fields?.isSingle ?? false}
                            onChange={(evt, value) => setFields('isSingle', value)}
                        />
                    }
                    label="Single release"
                />
            </Grid>
            <Grid item xs={12}>
                <ImageFileUpload
                    preview={fields?.img ?? null}
                    error={isError('imageFile')}
                    label="Album cover"
                    subtitle="Use a high quality image to represent your album cover."
                    onChange={(file) => setFields('imageFile', file)}
                />
            </Grid>
        </Grid>
    )
}

export default AlbumsCreator
