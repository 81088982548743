import { Button, Grid, TextField, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import useFirestore from '../../hooks/useFirestore';
import useRecordings from '../../hooks/useRecordings';
import useWallet from '../../hooks/useWallet';
import { IRecording } from '../../models/IRecording';
import { TransactionStatus, TransactionType } from '../../models/ITransaction';
import { useUser } from '../../store/context/UserContext';
import { ibanRegex } from '../../utils';
import AvatarRowItem from '../AvatarRowItem';

interface IFields {
    name?: string;
    iban?: string;
    amount?: string;
}

interface IErrors {
    name?: boolean;
    iban?: boolean;
    amount?: boolean;
    noISRC?: IRecording[];
}

const PayoutForm = () => {
    const [fields, setFields] = useState<IFields>({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [isErrorSubmitting, setIsErrorSubmitting] = useState(false);
    const [errors, setErrors] = useState<IErrors>({});
    const { add } = useFirestore();
    const { wallet, fetchTransactions } = useWallet();
    const { recordings, fetchRecordings } = useRecordings();
    const { state: { data: user } } = useUser();

    const handleSetError = (error: string, value: any) => setErrors((p) => ({ ...p, [error]: value }));

    useEffect(() => {
        if (user) {
            setFields((p) => ({
                ...p,
                iban: user.iban ?? '',
            }));
        }
    }, [user]);

    useEffect(() => {
        if (user && wallet) {
            setFields((p) => ({
                ...p,
                amount: wallet.amount.toFixed(2),
            }));
        }
    }, [user, wallet])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFields((p) => ({ ...p, [name]: value }));
    };

    const setFullAmount = () => setFields((p) => ({ ...p, amount: `${wallet.amount.toFixed(2)}` }));

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrors({});
        setIsErrorSubmitting(false);

        let error = false;
        if (!fields?.name) {
            handleSetError('name', true);
            error = true;
        }
        if (!fields?.iban || !fields.iban.match(ibanRegex)) {
            handleSetError('iban', true);
            error = true;
        }
        if (!fields?.amount) {
            handleSetError('amount', true); error = true;
            error = true;
        }
        if (!fields.amount || parseFloat(fields?.amount) < 10 || parseFloat(fields?.amount) > wallet.amount) {
            handleSetError('amount', true);
            error = true;
        }
        // Check if all approved recordings have an ISRC
        let _recordings = recordings;
        if (recordings.length === 0) {
            _recordings = await fetchRecordings();
        }

        const recordingsWithoutISRC = _recordings.filter((recording) => recording.approved && !recording.isrc ) ?? [];
        if (recordingsWithoutISRC.length > 0) {
            handleSetError('noISRC', recordingsWithoutISRC);
            error = true;
        }

        if (error) return;

        if (!user?.id) return;
        const data = {
            amount: parseFloat(fields.amount ?? ''),
            iban: fields.iban,
            status: TransactionStatus.Pending,
            ownerId: user.id,
            type: TransactionType.ROYALTIES_OUT,
            recipient: fields.name,
            createdAt: new Date(),
            target: 'OWNER'
        }

        setIsSubmitting(true);
        try {
            await add('transactions', data);
            await fetchTransactions({ force: true });
            setIsSubmitted(true);
            setIsSubmitting(false);
        } catch (err) {
            console.log('Adding transaction failed', err);
            setIsSubmitted(true);
            setIsSubmitting(false);
            setIsErrorSubmitting(true);
        }
    }

    if (isSubmitted) {
        return (
            <>
                <Typography variant="h5" fontWeight="bold" color={green[600]}>Payout requested</Typography>
                <Typography variant="body2">Your payout will be executed within 3 working days.</Typography>
            </>
        )
    }

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                name="iban"
                label="IBAN"
                size="small"
                value={fields?.iban ?? ''}
                onChange={handleInputChange}
                sx={{ width: "100%" }}
                error={errors.iban}
                helperText="IBAN must be associated with a phonogram owner"
            />
            <Box my={2} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="name"
                        label="Account name"
                        size="small"
                        value={fields?.name ?? ''}
                        helperText="Use company account if possible"
                        onChange={handleInputChange}
                        sx={{ width: "100%" }}
                        error={errors.name}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="amount"
                        label="Amount"
                        size="small"
                        type="number"
                        value={fields?.amount ?? ''}
                        onChange={handleInputChange}
                        error={errors.amount}
                        sx={{ width: "100%" }}
                        InputProps={{
                            inputProps: {
                                min: 0, max: wallet.amount, step: 0.01
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Box my={2} />
            {errors.noISRC && (
                <Box my={2}>
                    <Typography variant="body2" color="error">Unable to initiate payout. <br /> Following approved recordings don't have an ISRC:</Typography>
                    {errors.noISRC.map((recording, i) => <AvatarRowItem name={recording.name} img={recording.img && `${recording.img}?alt=media`} key={i} />)}
                </Box>
            )}
            {isErrorSubmitting && <Typography variant="body2" color="error">Something went wrong. Please try again.</Typography>}
            <Box my={2} />
            <Button variant="outlined" type="submit">Request payout</Button>
        </form>
    )
}

export default PayoutForm
