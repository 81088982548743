import { CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const FullscreenOverlayLoader = ({ content, info }: { content?: string, info?: any }) => {

    return (
        <Box
            sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 99999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: "rgba(0,0,0,0.5)"
        }}>
            {content ? (
                <Box p={4} bgcolor="#FFF" borderRadius={4} maxWidth="280px">
                    <Typography textAlign="center" fontWeight="bold"> {content}</Typography>
                    <Box my={2} display="flex" justifyContent="center"><CircularProgress /></Box>
                    {info ? (
                        <Typography textAlign="center">{info.submitted} / {info.total}</Typography>
                    ) : (<></>)}
                </Box>
            ) : <CircularProgress /> }

        </Box>
    )
}

export default FullscreenOverlayLoader
